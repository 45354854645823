import { textItemFamilyAtom } from "@/stores/Project";
import { selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import { TextItemMetaData } from "@ds/organisms/TextItemMetadata";
import { ActualComponentStatus } from "@shared/types/TextItem";
import { useAtom, useAtomValue } from "jotai";
import style from "./style.module.css";

const users = ["John Doe", "Jane Smith", "Bob Johnson"];
const allTags = [
  { value: "tag1", label: "Tag 1" },
  { value: "tag2", label: "Tag 2" },
];

function MetadataPanel() {
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const [textItem, setTextItem] = useAtom(textItemFamilyAtom(selectedTextItemIds[0]));

  if (selectedTextItemIds.length > 1) {
    return <div className={style.wrapper}>Multiple text items selected</div>;
  }

  const setText = (text: string) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, text }));
  };

  const setStatus = (status: ActualComponentStatus) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, status }));
  };

  const setAssignee = (assignee: string) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, assignee }));
  };

  const setTags = (tags: { value: string; label: string }[]) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, tags: tags.map((tag) => tag.value) }));
  };

  const setNotes = (notes: string) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, notes }));
  };

  const setCharacterLimit = (characterLimit: number) => {
    setTextItem((prevTextItem) => ({ ...prevTextItem, characterLimit }));
  };

  return (
    <div className={style.wrapper}>
      <TextItemMetaData
        text={textItem.text}
        setText={setText}
        status={textItem.status}
        setStatus={setStatus}
        users={users}
        assignee={textItem.assignee ?? ""}
        setAssignee={setAssignee}
        allTags={allTags}
        tags={textItem.tags.map((tag) => ({ value: tag, label: tag }))}
        setTags={setTags}
        notes={textItem.notes ?? ""}
        setNotes={setNotes}
        characterLimit={textItem.characterLimit ?? 0}
        setCharacterLimit={setCharacterLimit}
        onCancel={() => {}}
        onSave={() => {}}
        onInsertVariable={() => {}}
        showCTAButtons={true}
      />
    </div>
  );
}

export default MetadataPanel;
