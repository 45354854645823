import { debouncedProjectContentSearchQueryAtom, projectContentSearchQueryAtom } from "@/stores/Project";
import NavMenuItem from "@ds/molecules/NavMenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import style from "./style.module.css";

function FilterBarSearch() {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery] = useAtom(projectContentSearchQueryAtom);
  const [_, setSearchQuery] = useAtom(debouncedProjectContentSearchQueryAtom);

  function handlePotentialSearchHotkey(event: KeyboardEvent) {
    // metaKey = Cmd on macOS
    // ctrlKey = Ctrl on Windows
    // key 70 = "f"
    if ((event.metaKey || event.ctrlKey) && event.key === "f") {
      // If search is already active, allow the browser's default behavior
      if (isSearchActive) {
        dispatchEvent(event);
        return;
      }

      setIsSearchActive(true);
      event.preventDefault();
    }

    if (event.key === "Escape") {
      setSearchQuery("");
      setIsSearchActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handlePotentialSearchHotkey);

    return () => {
      window.removeEventListener("keydown", handlePotentialSearchHotkey);
    };
  }, [isSearchActive]);

  if (!isSearchActive) {
    return <NavMenuItem Icon={<SearchIcon />} label="Search" onClick={() => setIsSearchActive(true)} />;
  }

  return (
    <input
      autoFocus
      className={style.searchInput}
      type="search"
      placeholder="Find..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
}

export default FilterBarSearch;
