import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  Icon: React.ReactNode;
  label: string;

  active?: boolean;
  collapsed?: boolean;

  className?: string;
  style?: React.CSSProperties;

  onClick?: () => void;
}

const NavMenuItem = (props: IProps) => {
  return (
    <div
      className={classNames(style.navLink, props.className, {
        [style.active]: props.active,
        [style.collapsed]: props.collapsed,
      })}
      onClick={props.onClick}
    >
      <Icon Icon={props.Icon} size="small" className={style.icon} />
      <div
        className={classNames(style.linkText, {
          [style.collapsed]: props.collapsed,
        })}
      >
        <Text>{props.label}</Text>
      </div>
    </div>
  );
};

export default NavMenuItem;
