import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import * as RSelect from "@radix-ui/react-select";
import React from "react";
import SelectOptionWrapper from "../../atoms/SelectOption";
import SelectTrigger from "../../atoms/SelectTrigger";
import Text from "../../atoms/Text";
import style from "./index.module.css";

type SelectOption = {
  type: "option";
  text: string;
  value: string;
  onClick?: () => void;
  disabled?: boolean;
};

type SelectLabel = {
  type: "label";
  label: string;
};

type SelectSeparator = {
  type: "separator";
};

export type SelectItem = SelectOption | SelectLabel | SelectSeparator;
export type SelectPosition = "item-aligned" | "popper";

export interface ISelectProps {
  value: string;
  onChange: (value: string) => void;
  children: React.ReactNode;

  leadingIcon?: React.ReactNode;
  id?: string;
  placeholder?: string;
  inline?: boolean;
  className?: string;
  style?: React.CSSProperties;
  position?: SelectPosition;
  state?: "default" | "disabled" | "error";
  errorMessage?: string;
}

/**
 * `<select>` component built with Radix UI.
 * Usage:
 * ```
 * <Select value={value} onChange={onChange} placeholder="Select an option...">
 *   <SelectOption label="Option 1" value="option-1" />
 *   <SelectOption label="Option 2" value="option-2" />
 *   <SelectOption label="Option 3" value="option-3" />
 * </Select>
 * ```
 *
 * or with labels and separators:
 * ```
 * <Select value={value} onChange={onChange} placeholder="Select an option...">
 *   <SelectGroup>
 *     <SelectLabel>Label</SelectLabel>
 *     <SelectOption label="Option 1" value="option-1" />
 *     <SelectOption label="Option 2" value="option-2" />
 *     <SelectOption label="Option 3" value="option-3" />
 *   </SelectGroup>
 *   <SelectSeparator />
 *   <SelectGroup>
 *     <SelectLabel>Label 2</SelectLabel>
 *     <SelectOption label="Option 4" value="option-4" />
 *   </SelectGroup>
 * </Select>
 * ```
 */
export function Select(props: ISelectProps) {
  const { inline = false, state = "default", errorMessage } = props;

  return (
    <>
      <RSelect.Root value={props.value} onValueChange={props.onChange}>
        <RSelect.Trigger disabled={state === "disabled"} asChild>
          <SelectTrigger inline={inline} leadingIcon={props.leadingIcon} state={state}>
            <RSelect.Value aria-label={props.value} placeholder={props.placeholder} id={props.id} />
          </SelectTrigger>
        </RSelect.Trigger>

        <RSelect.Portal>
          <RSelect.Content className={style.contentContainer} position={props.position}>
            <RSelect.ScrollUpButton className={style.scrollButton}>
              <ExpandLess />
            </RSelect.ScrollUpButton>

            <RSelect.Viewport className={style.selectViewport}>{props.children}</RSelect.Viewport>

            <RSelect.ScrollDownButton className={style.scrollButton}>
              <ExpandMore />
            </RSelect.ScrollDownButton>
          </RSelect.Content>
        </RSelect.Portal>
      </RSelect.Root>
      {errorMessage && (
        <Text color="danger" size="small">
          {errorMessage}
        </Text>
      )}
    </>
  );
}

interface SelectOptionProps {
  label: string;
  value: string;

  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  helpText?: string;
  disabled?: boolean;

  className?: string;
  style?: React.CSSProperties;
}
export const SelectOption = React.forwardRef<HTMLDivElement, SelectOptionProps>(function SelectItem(props, ref) {
  return (
    <RSelect.Item value={props.value} asChild>
      <SelectOptionWrapper {...props} ref={ref}>
        {/*
         * Be careful about putting anything in this ItemText; Radix will use all the children as the
         * selected value after the popup closes.
         */}
        <RSelect.ItemText asChild>
          <Text>{props.label}</Text>
        </RSelect.ItemText>
      </SelectOptionWrapper>
    </RSelect.Item>
  );
});

interface SelectSeparatorProps {}
export const SelectSeparator = React.forwardRef<HTMLDivElement, SelectSeparatorProps>(function SelectItem(props, ref) {
  return <RSelect.Separator {...props} ref={ref} className={style.selectSeparator} />;
});

interface SelectLabelProps {
  children: React.ReactNode;
}
export const SelectLabel = React.forwardRef<HTMLDivElement, SelectLabelProps>(function SelectLabel(props, ref) {
  return (
    <RSelect.Label {...props} ref={ref} className={style.selectLabel}>
      <Text size="small">{props.children}</Text>
    </RSelect.Label>
  );
});

interface SelectGroup {
  children: React.ReactNode;
}
export const SelectGroup = React.forwardRef<HTMLDivElement, SelectGroup>(function SelectGroup(props, ref) {
  return <RSelect.Group {...props} ref={ref} />;
});

export default Select;
