import Comments from "../Comments";
import EditTabBar from "../EditTabBar";
import Instances from "../Instances";
import MetadataPanel from "../Metadata";
import TitleRow from "../TitleRow";

function ProjectDetailsPanel() {
  return (
    <>
      <TitleRow />
      <EditTabBar />
      <Instances />
      <MetadataPanel />
      <Comments />
    </>
  );
}

export default ProjectDetailsPanel;
