import { IFDittoProjectBlock } from "@shared/types/DittoProject";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { IFTextItem } from "@shared/types/TextItem";
import { createHttpRequest } from "./lib/createHttpRequest";

export const getProject = createHttpRequest<
  { projectId: string; projectContentSearchQuery?: string },
  IFDittoProjectData
>({
  method: "get",
  getUrl({ projectId, projectContentSearchQuery }) {
    let url = `/ditto-project/${projectId}`;
    const searchParams = new URLSearchParams();
    if (projectContentSearchQuery) {
      searchParams.append("search", projectContentSearchQuery);
    }
    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }
    return url;
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, IFTextItem[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const getBlocks = createHttpRequest<{ ids: string[]; projectId: string }, IFDittoProjectBlock[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});
