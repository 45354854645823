import * as httpDittoProject from "@/http/dittoProject";
import atomWithDebounce from "@shared/frontend/stores/atomWithDebounce";
import batchedAsyncAtomFamily, { REFRESH, REFRESH_SILENTLY } from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { atom } from "jotai";
import { splitAtom, unwrap } from "jotai/utils";

// MARK: - Source Atoms

/**
 * The source of truth for the project Id. This atom should be set when the user
 * navigates to a project.
 */
export const projectIdAtom = atom<string | null>(null);

export const {
  currentValueAtom: projectContentSearchQueryAtom,
  debouncedValueAtom: debouncedProjectContentSearchQueryAtom,
} = atomWithDebounce("");

export const textItemFamilyAtom = batchedAsyncAtomFamily({
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getTextItems({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Text Item",
});

/**
 * The source of truth for blocks. You should be fetching/updating blocks
 * through this atom to ensure that the UI updates correctly.
 *
 * This atom should be consumed like a mix between a Jotai [FamilyAtom](https://jotai.org/docs/utilities/family) and [AtomWithReset](https://jotai.org/docs/utilities/resettable#atomwithreset).
 */
export const blockFamilyAtom = batchedAsyncAtomFamily({
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getBlocks({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Block",
});

/**
 * Fetches a project by its Id.
 */
async function fetchProjectById(projectId: string, projectContentSearchQuery: string) {
  const [request] = httpDittoProject.getProject({ projectId });
  const { data: project } = await request;
  return project;
}

export const projectAtom = atom(
  async (get) => {
    const projectId = get(projectIdAtom);
    const projectContentSearchQuery = get(debouncedProjectContentSearchQueryAtom);

    if (!projectId) throw new Error("projectIdAtom is not set");

    return await fetchProjectById(projectId, projectContentSearchQuery);
  },
  async (
    get,
    set,
    newValue:
      | Promise<IFDittoProjectData>
      | IFDittoProjectData
      | ((
          previousValue: Promise<IFDittoProjectData> | IFDittoProjectData
        ) => Promise<IFDittoProjectData> | IFDittoProjectData)
      | typeof REFRESH
      | typeof REFRESH_SILENTLY
  ) => {
    if (newValue === REFRESH) {
      const projectId = get(projectIdAtom);
      const projectContentSearchQuery = get(debouncedProjectContentSearchQueryAtom);

      if (!projectId) throw new Error("projectIdAtom is not set");
      set(projectAtom, fetchProjectById(projectId, projectContentSearchQuery));
    } else if (newValue === REFRESH_SILENTLY) {
      const projectId = get(projectIdAtom);
      const projectContentSearchQuery = get(debouncedProjectContentSearchQueryAtom);

      if (!projectId) throw new Error("projectIdAtom is not set");
      fetchProjectById(projectId, projectContentSearchQuery).then((project) => set(projectAtom, project));
    } else if (newValue instanceof Function) {
      set(projectAtom, newValue(await get(projectAtom)));
    } else {
      set(projectAtom, newValue);
    }
  }
);

// MARK: - Derived Atoms

export const projectNameAtom = atom(async (get) => {
  const project = await get(projectAtom);
  return project.name;
});

export const projectBlocksAtom = atom(async (get) => {
  const project = await get(projectAtom);
  return project.blocks;
});

export const projectBlocksSplitAtom = splitAtom(unwrap(projectBlocksAtom, (prev) => prev ?? []));

export const projectTextItemsCountAtom = atom((get) => {
  const blocks = get(unwrap(projectBlocksAtom, (prev) => prev ?? []));
  return blocks.reduce((acc, block) => acc + block.textItems.length, 0);
});

export const projectHiddenResultsTextItemsCountAtom = atom((get) => {
  const blocks = get(unwrap(projectBlocksAtom, (prev) => prev ?? []));
  return blocks.reduce((acc, block) => acc + block.allTextItems.length - block.textItems.length, 0);
});

export interface INavBlockItem {
  _id: string;
  type: "block";
}

export interface INavTextItem {
  _id: string;
  type: "text";
  sortKey: string;
}

export interface INavMessageItem {
  _id: string;
  type: "message";
  message: string;
}

export function isValidBlock(block: any): block is INavBlockItem {
  return block && block._id;
}

export const flattenedProjectItemsAtom = atom(async (get) => {
  const project = await get(projectAtom);
  const flattenedProjectItems = project.blocks.reduce<(INavBlockItem | INavTextItem | INavMessageItem)[]>(
    (acc, block) => {
      if (isValidBlock(block)) {
        acc.push({ _id: block._id, type: "block" });
        acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
        /**
         * Add a user-friendly message that summarizes # of text items hidden from this block due to search results,
         * if applicable
         */
        const hiddenTextItemsCount = block.allTextItems.length - block.textItems.length;
        if (hiddenTextItemsCount > 0) {
          acc.push({
            _id: `${block._id}_hidden_search_results`,
            type: "message",
            message: `${hiddenTextItemsCount} text ${hiddenTextItemsCount === 1 ? "item" : "items"} not shown`,
          });
        }
      } else {
        acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
      }
      return acc;
    },
    []
  );

  /**
   * Build a user-friendly message that summarizes # of blocks / root text items hidden from search results,
   * if applicable
   */
  const hiddenProjectMessageStringComponents: string[] = [];

  if (project.hiddenBlocksCount > 0) {
    hiddenProjectMessageStringComponents.push(
      `${project.hiddenBlocksCount} ${project.hiddenBlocksCount === 1 ? "block" : "blocks"}`
    );
  }
  if (project.hiddenRootTextItemsCount > 0) {
    hiddenProjectMessageStringComponents.push(
      `${project.hiddenRootTextItemsCount} text ${project.hiddenRootTextItemsCount === 1 ? "item" : "items"}`
    );
  }

  const hiddenProjectItemsMessage = hiddenProjectMessageStringComponents.length
    ? `${hiddenProjectMessageStringComponents.join(" and ")} not shown`
    : "";

  return {
    flattenedProjectItems,
    hiddenProjectItemsMessage,
  };
});
