import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import "@reach/combobox/styles.css";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

type Item = {
  value: string;
  label: string;
};

interface IProps {
  options: Item[];
  selectedItems: Item[];
  setSelectedItems: (items: Item[]) => void;

  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  focusedPlaceholder?: string;
  createNewText?: string;

  onCreateNew?: (value: string) => void;
}

export function MultiCombobox(props: IProps) {
  const [query, setQuery] = useState("");

  const { selectedItems, setSelectedItems } = props;

  const filteredOptions = useMemo(
    () =>
      [
        // always list all of the selected items at the top of the list
        ...props.options
          .filter((item) => selectedItems.some((selectedItem) => selectedItem.value === item.value))
          .filter((item) => item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
          .sort((a, b) => a.label.localeCompare(b.label)),
        ,
        ...props.options
          .filter((item) => !selectedItems.some((selectedItem) => selectedItem.value === item.value))
          .filter((item) => item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ] as Item[],
    [query, props.options, selectedItems]
  );

  const showCreateNew = query != "" && !filteredOptions.some((item) => item.value === query);

  const selectedItemsRef = useRef<HTMLDivElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  // individual refs for each selected item button
  const selectedItemButtonsRef = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    selectedItemButtonsRef.current = selectedItemButtonsRef.current.slice(0, selectedItems.length);
  }, [selectedItems]);

  function deselectItem(item: Item) {
    const newSelectedItems = selectedItems.filter((selectedItem) => selectedItem.value !== item.value);
    onChangeValue(newSelectedItems);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    // if the user presses delete and there's no query, focus the last selected item
    if (query === "" && event.key === "Backspace") {
      const newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
      setSelectedItems(newSelectedItems);
    }
  }

  function onCreateNew(value: string) {
    props.onCreateNew?.(value);
    const newValues = [...selectedItems, { value, label: value }];
    onChangeValue(newValues);
  }

  function onChangeValue(values: Item[]) {
    setSelectedItems(values);
  }

  return (
    <Combobox immediate multiple value={selectedItems} by="value" onChange={onChangeValue} onClose={() => setQuery("")}>
      {({ open }) => (
        <div
          className={style.combobox}
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          <ComboboxInput
            ref={inputRef}
            // @ts-ignore
            onKeyDown={handleKeyDown}
            placeholder={(selectedItems.length > 0 ? (open ? props.focusedPlaceholder : "") : props.placeholder) ?? ""}
            onChange={(event) => setQuery(event.target.value)}
            className={style.innerInput}
            selectedItems={selectedItems}
            // these are passed in so that the InputComponent can access them
            open={open}
            removeSelectedItem={deselectItem}
            selectedItemButtonsRef={selectedItemButtonsRef}
            selectedItemsRef={selectedItemsRef}
            inputWrapperRef={inputWrapperRef}
            optionsListRef={optionsListRef}
            query={query}
            as={InputComponent}
          />

          <ComboboxOptions modal={false} className={style.optionsList} ref={optionsListRef}>
            {filteredOptions.map((option) => (
              <ComboboxOption key={option.value} value={option} className={classNames(style.option)}>
                {({ selected }) => (
                  <>
                    <Checkbox
                      checked={selected}
                      onChange={() => {}}
                      className={style.checkbox}
                      size="sm"
                      color="invert"
                    />
                    <Text>{getBoldedMatchingText(option.label, query)}</Text>
                  </>
                )}
              </ComboboxOption>
            ))}

            {showCreateNew && (
              <div
                key="create-new"
                className={classNames(style.option, style.createNew)}
                onClick={() => onCreateNew(query)}
              >
                <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
                <Text className={style.createNewText}>
                  <span className={style.labelText}>{props.createNewText || "Create: "}</span>
                  <span className={style.queryText}>"{query}"</span>
                </Text>
              </div>
            )}
          </ComboboxOptions>
        </div>
      )}
    </Combobox>
  );
}

interface InputProps {
  selectedItems: Item[];
  removeSelectedItem: (item: Item) => void;
  selectedItemButtonsRef: React.MutableRefObject<(HTMLButtonElement | null)[]>;
  selectedItemsRef: React.MutableRefObject<HTMLDivElement | null>;
  inputWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  optionsListRef: React.MutableRefObject<HTMLDivElement | null>;
  open: boolean;
  query: string;
  placeholder: string;
}
const InputComponent = React.forwardRef<HTMLInputElement | null, InputProps>(function ComboboxInput(
  props,
  forwardedRef
) {
  const {
    selectedItems,
    removeSelectedItem,
    selectedItemButtonsRef,
    selectedItemsRef,
    inputWrapperRef,
    optionsListRef,
    open,
    ...rest
  } = props;

  useEffect(
    function calculateSelectedItemsSize() {
      if (inputWrapperRef.current && optionsListRef.current) {
        const inputWrapperWidth = inputWrapperRef.current.getBoundingClientRect().width;
        optionsListRef.current.style.setProperty("--input-wrapper-width", `${inputWrapperWidth}px`);
      }
    },
    [selectedItems, open]
  );

  const measuredInputRef = useRef<HTMLSpanElement>(null);

  useEffect(
    function setInputWidth() {
      if (measuredInputRef.current && inputWrapperRef.current) {
        const width = measuredInputRef.current.getBoundingClientRect().width + 6;
        inputWrapperRef.current.style.setProperty("--input-width", `${width}px`);
      }
    },
    [measuredInputRef.current, props.placeholder, props.query]
  );

  return (
    <div
      className={classNames(style.inputWrapper, {
        [style.open]: open,
      })}
      ref={inputWrapperRef}
    >
      {selectedItems.map((item, index) => (
        <Button
          key={item.value}
          color="invert"
          onClick={() => removeSelectedItem(item)}
          ref={(el) => (selectedItemButtonsRef.current[index] = el)}
          clickOnBackspace
          className={style.selectedItem}
        >
          {item.label}
        </Button>
      ))}

      <input {...rest} ref={forwardedRef} className={style.input} />

      <span ref={measuredInputRef} className={style.measureInput}>
        {props.query || props.placeholder}
      </span>
    </div>
  );
});

export default MultiCombobox;
