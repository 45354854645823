import { TextareaAutosize } from "@mui/base";
import DragIndicator from "@mui/icons-material/DragIndicator";
import classNames from "classnames";
import React, { useEffect } from "react";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

export type TextItemComponentState = "default" | "focus" | "disabled" | "dragging" | "editing-inline";

interface IProps {
  text: string;
  displayText?: React.ReactNode;

  status?: "NONE" | "WIP" | "REVIEW" | "FINAL";
  tags?: string[];
  notes?: string | null;

  canDrag?: boolean;

  state?: TextItemComponentState;
  expansion?: "inline" | "block";
  variant?: "default" | "published" | "unmanaged";
  showTextIcon?: boolean;

  onClickSave?: (value: string) => void;
  onClickCancel?: () => void;

  className?: string;
  style?: React.CSSProperties;
}

export function TextItem(props: IProps) {
  const isEditing = props.state === "editing-inline";

  function handleSave(value: string) {
    props.onClickSave?.(value);
  }

  function handleCancel() {
    props.onClickCancel?.();
  }

  return (
    <div
      style={props.style}
      className={classNames(
        style.TextItemWrapper,
        {
          [style.inline]: props.expansion === "inline",
          [style[`state-${props.state}`]]: props.state,
          [style[`variant-${props.variant}`]]: props.variant,
        },
        props.className
      )}
      data-testid="text-item"
    >
      {props.status && (
        <div
          className={classNames(style.status, {
            [style[`status-${props.status}`]]: props.status,
          })}
        />
      )}
      {props.canDrag && <Icon Icon={<DragIndicator />} className={style.DragIndicator} size="xs" color="minimal" />}
      {props.showTextIcon && <TextIcon />}

      <div className={style.main}>
        <div className={style.textRow}>
          {!isEditing && <Text>{props.displayText ?? props.text}</Text>}
          {isEditing && <EditableInput defaultValue={props.text} onSave={handleSave} onCancel={handleCancel} />}
        </div>

        {props.notes && (
          <Text size="micro" color="secondary">
            {props.notes}
          </Text>
        )}

        {props.tags && props.tags.length > 0 && (
          <div className={style.tags}>
            {props.tags?.map((tag) => (
              <Badge key={tag} color="empty" size="xs" className={style.tag}>
                {tag}
              </Badge>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

interface EditableInputProps {
  defaultValue: string;
  onSave: (value: string) => void;
  onCancel: () => void;
}

function EditableInput(props: EditableInputProps) {
  const [inputValue, setInputValue] = React.useState(props.defaultValue);

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInputValue(e.target.value);
  }

  function handleSave() {
    props.onSave(inputValue);
  }

  function handleCancel() {
    props.onCancel();
  }

  useEffect(
    function focusTextAreaOnMount() {
      if (textAreaRef.current) {
        textAreaRef.current.focus();

        // looks kind of insane, but sets the cursor to the end of the text area
        textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = inputValue.length;
      }
    },
    [textAreaRef.current]
  );

  return (
    <div className={style.editableInput}>
      <TextareaAutosize placeholder="Edit text" value={inputValue} onChange={handleChange} ref={textAreaRef} />

      <div className={style.inlineEditButtons}>
        <Button variant="text" onClick={handleCancel}>
          <Text color="action">Cancel</Text>
        </Button>

        <Button onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
}

function TextIcon() {
  return (
    <Icon
      Icon={
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 3H13V4V6H12V4H8.5V12H10V13H6V12H7.5V4H4V6H3V4V3Z"
            fill="#A3A3A3"
          />
        </svg>
      }
      size="xs"
    />
  );
}

export default TextItem;
