import classNames from "classnames";
import React, { forwardRef } from "react";
import Icon from "../Icon";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  children: React.ReactNode;

  /**
   * The icon to be rendered. Intended for use with @mui/icons-material.
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   *
   * This is *not* the way to render an icon-only button. Use the variant="icon" prop and pass in the icon
   * as the children instead.
   */
  leadingIcon?: React.ReactNode | React.ReactSVGElement;

  /**
   * The color of the icon. Defaults to the color of the label.
   */
  iconColor?: "primary" | "secondary" | "minimal" | "positive" | "warning" | "danger" | string;
  iconSize?: "xs" | "small" | "base";

  size?: "base" | "small";
  variant?: "base" | "text" | "icon";
  color?: "primary" | "secondary" | "danger" | "invert" | "inherit";
  expansion?: "inline" | "block";
  state?: "default" | "hover" | "focus" | "active";
  disabled?: boolean;

  clickOnBackspace?: boolean;

  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = forwardRef<HTMLButtonElement, IProps>((props, ref) => {
  const size = props.size ?? "base";
  const variant = props.variant ?? "base";
  const color = props.color ?? "primary";
  const state = props.state ?? "default";
  const expansion = props.expansion ?? "inline";
  const disabled = props.disabled ?? false;

  const iconColor = props.iconColor ?? "inherit";
  const iconSize = props.iconSize ?? "small";

  function handleKeyDown(event: React.KeyboardEvent<HTMLButtonElement>) {
    if (event.key === "Backspace" && props.clickOnBackspace) {
      props.onClick?.();
    }
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (props.onClick) {
      event.stopPropagation();
      props.onClick(event);
    }
  }

  return (
    <button
      {...props}
      style={props.style}
      className={classNames(
        style.ButtonWrapper,
        {
          [style[`color-${color}`]]: true,
          [style[`size-${size}`]]: true,
          [style[`variant-${variant}`]]: true,
          [style[`state-${state}`]]: true,
          [style[`expansion-${expansion}`]]: true,
        },
        props.className
      )}
      onClick={handleClick}
      data-testid="button"
      ref={ref}
      disabled={disabled}
      onKeyDown={handleKeyDown}
    >
      {props.leadingIcon && <Icon Icon={props.leadingIcon} size={iconSize} style={{ color: iconColor }} />}
      {variant !== "icon" && props.children}
      {variant === "icon" && <Icon Icon={props.children} size={iconSize} style={{ color: "inherit" }} />}
    </button>
  );
});

export default Button;
