import {
  debouncedProjectContentSearchQueryAtom,
  projectBlocksSplitAtom,
  projectTextItemsCountAtom,
  textItemFamilyAtom,
} from "@/stores/Project";
import { selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import Scrollbar from "@ds/molecules/Scrollbar";
import TextItem, { TextItemComponentState } from "@ds/molecules/TextItem";
import TextItemBlock from "@ds/molecules/TextItemBlock";
import AddIcon from "@mui/icons-material/Add";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { Atom, useAtom, useAtomValue } from "jotai";
import { Suspense, useState } from "react";
import style from "./style.module.css";

function TextItemList() {
  const projectBlocksAtoms = useAtomValue(projectBlocksSplitAtom);

  const numTextItems = useAtomValue(projectTextItemsCountAtom);

  function addNewTextItem(blockId?: string) {
    console.log("add new text item", blockId);
  }

  function addNewBlock() {
    console.log("add new block");
  }

  const emptyProject = numTextItems === 0;

  return (
    <div className={style.main}>
      <Scrollbar className={style.scrollWrapper}>
        <div className={style.textItemListContainer}>
          {emptyProject && (
            <Button className={style.emptyProjectButton} variant="text" iconSize="xs" leadingIcon={<AddIcon />}>
              Add your first text item to this project
            </Button>
          )}

          {projectBlocksAtoms.map((projectBlockAtom, index) => (
            <TextItemBlockWrapper textItemBlockAtom={projectBlockAtom} index={index} />
          ))}
        </div>
        <div className={style.addItems}>
          <Button variant="text" iconSize="xs" size="small" leadingIcon={<AddIcon />} onClick={() => addNewTextItem()}>
            Add text item
          </Button>

          <Button variant="text" iconSize="xs" size="small" leadingIcon={<AddIcon />} onClick={addNewBlock}>
            Create block
          </Button>
        </div>
      </Scrollbar>
    </div>
  );
}

function TextItemRow({ textItemId }) {
  return (
    <Suspense key={textItemId} fallback={<TextItem key={textItemId} text={"Loading..."} />}>
      <TextItemWrapper textItemId={textItemId} />
    </Suspense>
  );
}

function TextItemWrapper(props: { textItemId: string }) {
  const [textItem, setTextItem] = useAtom(textItemFamilyAtom(props.textItemId));
  const [selectedTextItemIds, setSelectedTextItemIds] = useAtom(selectedTextItemIdsAtom);
  const [projectContentSearchQuery] = useAtom(debouncedProjectContentSearchQueryAtom);

  const [editingInline, setEditingInline] = useState(false);

  // on first click, select the text item. on second click, start editing.
  function onClick() {
    if (selectedTextItemIds.includes(textItem._id)) {
      setEditingInline(true);
    } else {
      setSelectedTextItemIds([...selectedTextItemIds, textItem._id]);
      // if we're going from text item unselected => selected, make sure we reset the editing state
      setEditingInline(false);
    }
  }

  function getDisplayState(): TextItemComponentState {
    // if (!isSelected) return "default";
    if (editingInline) return "editing-inline";
    if (isSelected) return "focus";
    return "default";
  }

  function handleSaveEdit(text: string) {
    // todo: mutate text item in jotai state
    // todo: persist edit to backend
    setEditingInline(false);
  }

  function handleCancelEdit() {
    setEditingInline(false);
    // note: the implementation of TextItem should handle discarding any in-progress edits.
    // don't handle that here!
  }

  const isSelected = selectedTextItemIds.includes(textItem._id);

  return (
    <div onClick={onClick}>
      <TextItem
        text={textItem.text}
        displayText={<SearchHighlightedText text={textItem.text} highlightedPhrase={projectContentSearchQuery} />}
        status={textItem.status}
        tags={textItem.tags}
        notes={textItem.notes}
        state={getDisplayState()}
        onClickCancel={handleCancelEdit}
        onClickSave={handleSaveEdit}
      />
    </div>
  );
}

function TextItemBlockWrapper(props: { textItemBlockAtom: Atom<IFDittoProjectData["blocks"][number]>; index: number }) {
  const textItemBlock = useAtomValue(props.textItemBlockAtom);
  const [projectContentSearchQuery] = useAtom(debouncedProjectContentSearchQueryAtom);

  const [showAllTextItems, setShowAllTextItems] = useState(false);

  const numHiddenResultsTextItems = textItemBlock.allTextItems.length - textItemBlock.textItems.length;

  const textItemType = showAllTextItems ? "allTextItems" : "textItems";

  function handleReorderBlock(currentIndex: number, direction: "up" | "down") {
    console.log("reorder", currentIndex, direction);
  }

  function handleDeleteBlock(blockId: string) {
    console.log("delete", blockId);
  }

  function addNewTextItem(blockId?: string) {
    console.log("add new text item", blockId);
  }
  if (textItemBlock._id === null && textItemBlock.textItems.length === 0) {
    return (
      <div className={style.textItemsNotInBlock}>
        {textItemBlock.textItems.map((textItem) => (
          <TextItemRow key={textItem._id} textItemId={textItem._id} />
        ))}
      </div>
    );
  }

  return (
    <TextItemBlock
      name={
        <SearchHighlightedText text={textItemBlock.name ?? "Block"} highlightedPhrase={projectContentSearchQuery} />
      }
      onDeleteBlock={() => handleDeleteBlock(textItemBlock._id ?? "")}
      onMoveBlockUp={() => handleReorderBlock(props.index, "up")}
      onMoveBlockDown={() => handleReorderBlock(props.index, "down")}
      onAddNewTextItem={() => addNewTextItem(textItemBlock._id ?? undefined)}
    >
      {textItemBlock[textItemType].map((textItem) => (
        <TextItemRow key={textItem._id} textItemId={textItem._id} />
      ))}
      {numHiddenResultsTextItems > 0 && !showAllTextItems && (
        <div className={style.hiddenResultsMessageBlock}>
          <div>
            {numHiddenResultsTextItems} more text {numHiddenResultsTextItems === 1 ? "item" : "items"} in this block not
            shown in search result
          </div>
          <div className={style.hiddenResultsMessageBlockDivider}>•</div>
          <Button variant="text" size="base" onClick={() => setShowAllTextItems(true)}>
            Show all
          </Button>
        </div>
      )}
    </TextItemBlock>
  );
}

/**
 * A component that displays text with a highlighted word if it is present in the text
 *
 * @param props text: string; highlightedWord?: string - contains the text to be displayed and the optional phrase to be highlighted
 * @returns a div containing un-styled text with the highlighted word wrapped in a span which applies the highlighted background
 */
function SearchHighlightedText(props: { text: string; highlightedPhrase?: string }) {
  if (!props.highlightedPhrase?.trim()) {
    return <>{props.text}</>;
  }

  // Find the index of all matches in the text
  const indexes = [...props.text.matchAll(new RegExp(props.highlightedPhrase, "gi"))].map((a) => a.index);

  let cursor = 0;
  const highlightedText: React.ReactNode[] = [];

  while (cursor < props.text.length) {
    if (indexes.includes(cursor)) {
      highlightedText.push(
        <span className={style.highlighted}>
          {props.text.substring(cursor, cursor + props.highlightedPhrase.length)}
        </span>
      );
      cursor += props.highlightedPhrase.length;
      continue;
    }

    highlightedText.push(<>{props.text[cursor]}</>);
    // Move on to next character
    cursor += 1;
  }

  return <div>{highlightedText}</div>;
}

export default TextItemList;
