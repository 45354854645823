import ExpandMore from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;

  leadingIcon?: React.ReactNode;

  state: "default" | "disabled" | "error";
  inline?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const SelectTrigger = React.forwardRef<HTMLButtonElement, IProps>(function SelectTrigger(props, ref) {
  const inline = props.inline ?? false;

  return (
    <button
      {...props}
      style={props.style}
      className={classNames(style.selectTrigger, props.className, {
        [style[props.state]]: true,
        [style.inline]: inline,
      })}
      data-testid="select-trigger"
      ref={ref}
    >
      {props.leadingIcon && <Icon Icon={props.leadingIcon} size="xs" />}
      <span className={style.childrenWrapper}>{props.children}</span>
      <Icon Icon={<ExpandMore />} size="xs" color="secondary" />
    </button>
  );
});

export default SelectTrigger;
