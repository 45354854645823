import { atom, SetStateAction } from "jotai";

/**
 * This util acts as a typical atom, but provides two values: a realtime-updating value and a debounced value.
 * Useful for components like text inputs where the controlled input needs updated immediately, but reactions to
 * the current value do not need to be propagated on each keystroke.
 *
 * Mostly the same as Jotai's example here: https://jotai.org/docs/recipes/atom-with-debounce
 *
 * @param initialValue same as the first argument to `atom`, the initial value to store in state
 * @param delayMilliseconds the amount of time to wait after the last update before updating the debounced value
 * @param shouldDebounceOnReset whether to debounce the value when it is reset to the initial value passed in originally
 * @returns an object containing both the realtime-updating value and the debounced value
 */
export default function atomWithDebounce<T>(initialValue: T, delayMilliseconds = 500, shouldDebounceOnReset = false) {
  const prevTimeoutAtom = atom<ReturnType<typeof setTimeout> | undefined>(undefined);

  // DO NOT EXPORT currentValueAtom as using this atom to set state can cause
  // inconsistent state between currentValueAtom and debouncedValueAtom
  const _currentValueAtom = atom(initialValue);

  const debouncedValueAtom = atom(initialValue, (get, set, update: SetStateAction<T>) => {
    clearTimeout(get(prevTimeoutAtom));

    const prevValue = get(_currentValueAtom);
    const nextValue = typeof update === "function" ? (update as (prev: T) => T)(prevValue) : update;

    const onDebounceStart = () => {
      set(_currentValueAtom, nextValue);
    };

    const onDebounceEnd = () => {
      set(debouncedValueAtom, nextValue);
    };

    onDebounceStart();

    if (!shouldDebounceOnReset && nextValue === initialValue) {
      onDebounceEnd();
      return;
    }

    const nextTimeoutId = setTimeout(() => {
      onDebounceEnd();
    }, delayMilliseconds);

    // set previous timeout atom in case it needs to get cleared
    set(prevTimeoutAtom, nextTimeoutId);
  });

  return {
    currentValueAtom: atom((get) => get(_currentValueAtom)),
    debouncedValueAtom,
  };
}
