import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import TextInput from "../../atoms/TextInput";
import MultiCombobox from "../../molecules/MultiCombobox";
import Select, { SelectOption } from "../../molecules/Select";
import { AvatarIcon, FlagIcon, InfoIcon, ParagraphIcon, StatusIcon, TagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  text: string;
  setText: (value: string) => void;
  status: ActualComponentStatus;
  setStatus: (value: ActualComponentStatus) => void;
  users: string[];
  assignee: string | null;
  setAssignee: (value: string) => void;
  allTags: {
    value: string;
    label: string;
  }[];
  tags: {
    value: string;
    label: string;
  }[];
  setTags: (value: { value: string; label: string }[]) => void;
  notes: string;
  setNotes: (value: string) => void;
  characterLimit: number;
  setCharacterLimit: (value: number) => void;
  showCTAButtons?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onInsertVariable: () => void;
}

export function TextItemMetaData(props: IProps) {
  const characterLimitInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    function setCharacterLimitInputSize() {
      if (characterLimitInputRef.current) {
        characterLimitInputRef.current.style.width = `${(props.characterLimit + "").length * 7 + 18}px`;
      }
    },
    [props.characterLimit]
  );

  return (
    <div
      style={props.style}
      className={classNames(style.TextItemMetaDataWrapper, props.className)}
      data-testid="textItemMetaData"
    >
      <div className={classNames(style.flexStart, style.spacingBottomLg)}>
        <ParagraphIcon />
        <div className={style.textHeader}>Text</div>
      </div>
      <TextInput
        className={style.spacingBottomLg}
        expand="block"
        value={props.text}
        onChange={(value) => props.setText(value)}
      />
      <div className={classNames(style.flexApart, style.spacingButtonXl)}>
        <div className={style.insertVariableButton}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <path
              d="M8 4.57143H4.57143V8H3.42857V4.57143H0V3.42857H3.42857V0H4.57143V3.42857H8V4.57143Z"
              fill="#737373"
            />
          </svg>
          <span onClick={props.onInsertVariable}>Insert Variable</span>
        </div>
        <input
          ref={characterLimitInputRef}
          className={style.characterLimitInput}
          value={props.characterLimit + ""}
          onChange={(e) => {
            const value = e.target.value === "" ? 0 : parseInt(e.target.value);
            props.setCharacterLimit(value);
          }}
        />
      </div>
      <div className={style.flexApart}>
        <div>
          <FlagIcon />
          <span className={style.textItemMetaDataInputLabel}>Status</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <StatusSelect status={props.status} setStatus={props.setStatus} />
        </div>
      </div>
      <div className={style.flexApart}>
        <div>
          <AvatarIcon />
          <span className={style.textItemMetaDataInputLabel}>Assign</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <Select value={props.assignee ?? ""} onChange={(value) => props.setAssignee(value)}>
            {props.users.map((user) => (
              <SelectOption key={user} label={user} value={user} />
            ))}
          </Select>
        </div>
      </div>
      <div className={style.flexApart}>
        <div>
          <TagIcon />
          <span className={style.textItemMetaDataInputLabel}>Tags</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <MultiCombobox
            options={props.allTags}
            selectedItems={props.tags}
            setSelectedItems={(value) => props.setTags(value)}
            onCreateNew={(value) => props.setTags([...props.tags, { value, label: value }])}
          />
        </div>
      </div>
      <div className={style.flexApart}>
        <div>
          <InfoIcon />
          <span className={style.textItemMetaDataInputLabel}>Notes</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <TextInput size="sm" expand="block" value={props.notes} onChange={(value) => props.setNotes(value)} />
        </div>
      </div>
      {props.showCTAButtons && (
        <div className={style.ctaButtons}>
          <Button onClick={props.onCancel} className={style.cancelButton}>
            Cancel
          </Button>
          <Button onClick={props.onSave}>Save</Button>
        </div>
      )}
    </div>
  );
}

// Should move to its own organism?
interface IStatusSelectProps {
  status: ActualComponentStatus;
  setStatus: (status: ActualComponentStatus) => void;
}

function StatusSelect(props: IStatusSelectProps) {
  return (
    <Select
      value={props.status}
      onChange={(value) => props.setStatus(value as ActualComponentStatus)}
      leadingIcon={<StatusIcon status={props.status} />}
    >
      <SelectOption leadingIcon={<StatusIcon status="NONE" />} label="None" value="NONE" />
      <SelectOption leadingIcon={<StatusIcon status="WIP" />} label="Work in Progress" value="WIP" />
      <SelectOption leadingIcon={<StatusIcon status="REVIEW" />} label="Ready for Review" value="REVIEW" />
      <SelectOption leadingIcon={<StatusIcon status="FINAL" />} label="Final" value="FINAL" />
    </Select>
  );
}

export default TextItemMetaData;
