import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "placeholder"
    | "invert"
    | "code"
    | "action"
    | "purple"
    | "positive"
    | "warning"
    | "danger"
    | "info"
    | "template";
  weight?: "light" | "base" | "medium" | "strong";
  className?: string;
  size?: "base" | "small" | "micro";
  disabled?: boolean;
}

// TODO: typesafe text variations for different headers, font weights, etc
const Text = React.forwardRef<HTMLSpanElement, IProps>(function Text(props, ref) {
  const { children, disabled = false, weight = "base", ...rest } = props;

  const size = props.size || "base";
  const color = props.color || "primary";

  return (
    <span
      {...rest}
      className={classNames(style.TextWrapper, props.className, {
        [style[`size-${size}`]]: size,
        [style["disabled"]]: disabled,
        [style[`color-${color}`]]: color,
        [style[`weight-${weight}`]]: weight,
      })}
      data-testid="text"
      ref={ref}
    >
      {children}
    </span>
  );
});

export default Text;
